import React, { Component } from "react";
import Layout from "./../../Layout";
import { Grid, Tooltip } from "@mui/material";
import Card from "./components/Card/Card";
import { Trans as Translate} from 'react-i18next';
import { connect } from "react-redux";
import {
  fetchSummery,
  applyDevicesFilter,
  applyDevicesCatFilter,
  resetFilters,
  resetCatFilters,
} from "./../../Actions/Devices";
import barChartDefault from "./../../Components/common/Charts/barChartDefault";
import { ReactComponent as TrailerIcon } from "./../../assets/dashboard/truck.svg";
import { ReactComponent as NotRegistered } from "./../../assets/dashboard/not-registered.svg";
import { ReactComponent as GPSNotSupport } from "./../../assets/dashboard/gps-no-support.svg";
import { ReactComponent as OnlineStatus } from "./../../assets/dashboard/online.svg";
import { ReactComponent as OfflineStatus } from "./../../assets/dashboard/offline.svg";
import { ReactComponent as DriverIcon } from "./../../assets/dashboard/driver.svg";
import { ReactComponent as AssignedIcon } from "./../../assets/dashboard/assigned.svg";
import { ReactComponent as UnAssignedIcon } from "./../../assets/dashboard/not-assigned.svg";
import { ReactComponent as MovingIcon } from "./../../assets/dashboard/van-moving.svg";
import { ReactComponent as IdlingIcon } from "./../../assets/dashboard/idling.svg";
import { ReactComponent as TowingIcon } from "./../../assets/dashboard/tow-truck.svg";
import { ReactComponent as ParkingIcon } from "./../../assets/dashboard/parking.svg";
import { ReactComponent as GPSNotUpdated } from "./../../assets/dashboard/gps-not-updated.svg";
import { ReactComponent as StopIcon } from "./../../assets/dashboard/stop.svg";
import { ReactComponent as UnknownIcon } from "./../../assets/dashboard/unknown.svg";
import { ReactComponent as Nosignal } from "./../../assets/dashboard/newDashboards/NoSignal.svg";
import { ReactComponent as PowerCutIcon } from "./../../assets/monitoring/powerCut.svg";
import onlineSvg from "./../../assets/dashboard/newDashboards/TotalInvoices.svg"
import offlineSvg from "./../../assets/dashboard/newDashboards/Offline.svg"
import notRegisteredSvg from "./../../assets/dashboard/newDashboards/NotRegistered.svg"
import gpsNotUpdatedSvg from "./../../assets/dashboard/newDashboards/GPSnotUpdated.svg"
import "highcharts";
// import ReactHighcharts from "react-highcharts";
import moment from "moment";

import Skeleton from '@mui/material/Skeleton';

import Button from "../../Components/common/Button";
import Dialog from "../../Components/common/Dialog";
import DeviceSelector from "../../Components/Devices/DeviceSelector";
import DriverSelector from "../../Components/Devices/DriverSelector";
import { DistanceUnits, fonts, setAttributeFormat } from "../../Helpers";
import Scrollbar from "react-scrollbars-custom";
import CloseIcon from "@mui/icons-material/Close";
import "./Style.scss";
import pieChartDefault from "../../Components/common/Charts/pieChartDefault";
const initState = {
  userInfoFront: {},
  userFetch: false,
  devicesFetch: false,
  isFetching: false,
  enginHourReport: false,
  enginHourReportLoading: false,
  usersCount: {},
  devicesCount: {},
  report: {},
  mileage: {},
  pie1: null,
  positionsFetched: false,
  driverFetched: false,
  motionSelector: false,
  motionSelectorKey: "",
  deviceSelector: false,
  deviceSelectorKey: "",
  categorySelector: false,
  categorySelectorKey: "",
  geoSelector: false,
  geoSelectorKey: "",
  driverSelector: false,
  driverIds: [],
  driverSelectorKey: "",
  pieChart2: null,
  resArr2: {},
  resArr: {},
  drivers: {},
};

/* function compare (b, a) {
  if (a.y < b.y) {
    return -1
  }
  if (a.y > b.y) {
    return 1
  }
  return 0
} */

const colors = [
  "#7cb5ec",
  "#434348",
  "#90ed7d",
  "#f7a35c",
  "#2c2b79",
  "#f15c80",
  "#e4d354",
  "#2b908f",
  "#f45b5b",
  "#91e8e1",
  "#7cb5ec",
  "#434348",
  "#90ed7d",
  "#7cb5ec",
  "#434348",
  "#90ed7d",
  "#f7a35c",
  "#2c2b79",
  "#f15c80",
  "#e4d354",
  "#2b908f",
  "#f45b5b",
  "#91e8e1",
  "#7cb5ec",
  "#434348",
  "#90ed7d",
];
let colors1 = ["#008C7D", "#005270", "#FFA600", "#FF6A6C", "#A96AC3", "#6D71C5", "#007981", "#1E72BB", "#29AF5C", "#6FBD43"];

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initState,
      engineTabs:"daliyMilage"
    };
  }

  componentWillUnmount() {
    this.setState({
      ...initState,
    });
    this.props.dispatch(resetFilters());
    this.props.dispatch(resetCatFilters());
  }

  resetSelectors = () => {
    this.setState({
      motionSelector: false,
      motionSelectorKey: "",
      geoSelector: false,
      geoSelectorKey: "",
      deviceSelector: false,
      deviceSelectorKey: "",
      categorySelector: false,
      categorySelectorKey: "",
      driverSelector: false,
      driverSelectorKey: "",
      show: false,
      type: "",
      driverIds: [],
    });
  };

  openCategorySelector = (key, name) => {
    this.resetSelectors();
    this.setState(
      {
        show: true,
        type: "device",
      },
      () => {
        this.showSelectedUnits(key, name);
      }
    );
  };
  openMotionSelector = (key, name) => {
    this.resetSelectors();
    this.setState(
      {
        show: true,
        type: "device",
      },
      () => {
        this.showSelectedUnits(key, name);
      }
    );
  };
  openDeviceSelector = (key, name) => {
    this.resetSelectors();
    this.setState(
      {
        show: true,
        type: "device",
      },
      () => {
        this.showSelectedUnits(key, name);
      }
    );
  };
  openGeoSelector = (key, name) => {
    this.resetSelectors();
    this.setState(
      {
        show: true,
        type: "device",
      },
      () => {
        this.showSelectedUnits(key, name);
      }
    );
  };

  openDriverSelector = (driverIds, name) => {
    this.resetSelectors();
    this.setState({
      show: true,
      type: name,
      driverIds,
    });
  };

  componentWillMount() {
    this.prepareStates(this.props);
   

    if (this.props.logInUsers && this.props.logInUsers.id) {
      this.props.dispatch(fetchSummery());
    }
  }

  prepareDriver = ({ drivers, positions }) => {
    let fixDriver = null;
    const driverLinked = [];
    let driverLinked2 = [];

    fixDriver = this.props.devices3.filter(
      (data) => data.attributes.fixDriverId
    );
    fixDriver.map((d) => {
      driverLinked.push(d.attributes.fixDriverId);
    });
    drivers.map((dr) => {
      if (dr && dr.id && driverLinked.includes(dr.id)) {
        driverLinked2.push(dr.uniqueId);
      }
      return null;
    });

    let ids = [];
    let drIds = [];
    let inactive = [];
    positions.map((pos) => {
      if (
        pos &&
        pos.attributes &&
        pos.attributes.driverUniqueId &&
        this.props.allComputedAttributes.includes(pos.deviceId) &&
        pos.attributes.driverUniqueId !== "0"
      ) {
        if (
          !moment(pos.serverTime).isBefore(moment().subtract(10, "minutes"))
        ) {
          ids.push(pos.attributes.driverUniqueId);
        } else {
          inactive.push(pos.attributes.driverUniqueId);
        }
      }
      return null;
    });
    drivers.map((dr) => {
      if (dr && dr.uniqueId && dr.uniqueId !== "0") {
        drIds.push(dr.uniqueId);
      }
      return null;
    });
    const assigned = drIds.filter(
      (d) => driverLinked2.includes(d) || ids.includes(d)
    );
    const unassigned = drIds.filter(
      (d) => !ids.includes(d) && !driverLinked2.includes(d)
    );
    const unknown = ids.filter((d) => !drIds.includes(d));
    this.setState({
      drivers: {
        assigned,
        unassigned,
        unknown,
        inactive,
        // total:assigned.length + unassigned.length + unknown.length + inactive.length //it actually adds all values and shows wrong count
        total: drivers.length,
      },
    });
  };

  prepareStates = (n) => {
    if (
      n.positions &&
      // n.positions.length && //comment for new user created first staff(driver) not showing on dashboard
      n.drivers &&
      n.drivers.length &&
      n.allComputedAttributes
      // n.allComputedAttributes.length &&      //comment for new user created first staff(driver) not showing on dashboard
      // this.state.driverFetched === false
    ) {
      this.setState(
        {
          driverFetched: true,
        },
        () => {
          this.prepareDriver(n);
        }
      );
    }

    if (n.devices3 && n.devices3.length) {
      const notification = n.allNotifications;

      let notRegistered = notification.notRegistered;

      let cat = {};

      n.devices3.map((v) => {
        let c = v.category || "default";
        cat[c] = cat[c] || 0;
        cat[c]++;
        return null;
      });

      if (!this.state.pie1) {
        let objList = { total: 0, data: [] };
        Object.keys(cat).map((e, i) => {
          objList.total = n.devices3.length;
          objList.data.push({
            value: cat[e],
            color: colors[i],
            name: this.props.translate(e),
            key: e,
            image: <img src={`/assets/category/default/${e}.svg`} alt="" />,
          });
          return null;
        });

        this.setState({
          pie1: objList,
        });
      }

      let resArr2 = {
        total:
          notRegistered.length +
          notification.statusOnline.length +
          notification.statusOffline.length +
          notification.gpsNotUpdated.length,
        online: notification.statusOnline.length,
        offline: notification.statusOffline.length,
        notRegistered: notification.notRegistered.length,
        gpsNotUpdated: notification.gpsNotUpdated.length,
      };

      let resArr = {};
      const total =
        notification.Moving.length +
        notification.overSpeed.length +
        notification.gpsNotUpdated.length +
        notification.Idling.length +
        notification.Towing.length +
        notification.statusParking.length +
        notification.stop.length +
        notification.statusOffline.length;
      resArr = {
        total,
        data: [
          {
            value: notification.Moving.length,
            color: "#28a745",
            name: this.props.translate("moving"),
            key: "Moving",
            image: <MovingIcon width={20} height={20} fill="currentColor" />,
          },
          {
            value: notification.Idling.length,
            color: "#e4d354",
            name: this.props.translate("idling"),
            key: "Idling",
            image: <IdlingIcon width={20} height={20} fill="currentColor" />,
          },
          {
            value: notification.Towing.length,
            color: "#f45b5b",
            name: this.props.translate("towing"),
            key: "Towing",
            image: <TowingIcon width={20} height={20} fill="currentColor" />,
          },
          {
            value: notification.statusParking.length,
            color: "#1976d2",
            name: this.props.translate("parking"),
            key: "statusParking",
            image: <ParkingIcon width={20} height={20} fill="currentColor" />,
          },
          {
            value: notification.gpsNotUpdated.length,
            color: "#8d43b4",
            name: this.props.translate("GPSNotUpdated"),
            key: "gpsNotUpdated",
            image: <GPSNotUpdated width={20} height={20} fill="currentColor" />,
          },
          {
            value: notification.stop.length,
            color: "#2c2b79",
            name: this.props.translate("stop"),
            key: "stop",
            image: <StopIcon width={20} height={20} fill="currentColor" />,
          },
          {
            value: notification.statusOffline.length,
            color: "#dc3545",
            name: this.props.translate("unknown"),
            key: "statusOffline",
            image: <OfflineStatus width={20} height={20} fill="currentColor" />,
          },
          {
            value: notification.notRegistered.length,
            color: "#566374",
            key: "notRegistered",
            name: this.props.translate("tracker.notRegisteredYet"),
            image: <NotRegistered width={20} height={20} fill="currentColor" />,
          },
          {
            value: notification.powerCut.length,
            color: "#566374",
            key: "powerCut",
            name: this.props.translate("devicePowerCutOn"),
            image: <PowerCutIcon width={20} height={20} fill="currentColor" />,
          },
        ],
      };

      this.setState({
        resArr,
        pieChart2: resArr2,
      });
    }

    let reports = n.engineHourSummery.data;

    
    const fontId =
      (n.ServerSetting &&
        n.ServerSetting.attributes &&
        n.ServerSetting.attributes.fontFamily) ||
      "ff-4";
    const fontFamily = fonts[fontId];
    if (
      reports &&
      reports.length &&
      this.state.enginHourReportLoading === false
    ) {
      this.setState(
        {
          enginHourReportLoading: true,
        },
        () => {
          let report = reports;
          // if (report && report.length) {
          //   const max = { hours: 0, totalDistance: 0 };
          //   reports.forEach((r) => {
          //     if (
          //       r.totalDistance &&
          //       parseFloat(max.totalDistance) < parseFloat(r.totalDistance)
          //     ) {
          //       max.totalDistance = parseFloat(r.totalDistance);
          //     }
          //     if (r.hours > 0 && parseFloat(max.hours) < parseFloat(r.hours)) {
          //       max.hours = parseFloat(r.hours);
          //     }
          //   });
          
            this.setState({
              // report: { data: reports, max },
              report: reports,
              enginHourReportLoading: false,
            });
          // }
        }
      );
    }
  };

  componentWillReceiveProps(n) {
    if (
      this.state.isFetching === false &&
      n.positions &&
      n.positions.length &&
      n.ServerSetting &&
      n.logInUsers &&
      !(n.engineHourSummery && n.engineHourSummery.data)
    ) {
      this.setState({ isFetching: true }, () => {
        n.dispatch(
          fetchSummery({
            positions: n.positions,
            ServerSetting: n.ServerSetting,
            logInUsers: n.logInUsers,
          })
        );
      });
    }
    this.prepareStates(n);
  }

  checkLastResponseTime = (posDate, lastUpdate) => {
    return (
      posDate &&
      moment(posDate).isBefore(moment().subtract(10, "minutes")) &&
      !moment(lastUpdate).isBefore(moment().subtract(10, "minutes"))
    );
  };

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  showSelectedUnits = (data, type) => {
    if (type === "device") {
      let newList = [].concat(data);

      this.props.dispatch(applyDevicesCatFilter([]));
      this.props.dispatch(applyDevicesFilter(newList));
    }

    if (type === "geofence") {
      let newList = ["geoIds"];
      this.props.dispatch(applyDevicesCatFilter([]));
      this.props.dispatch(applyDevicesFilter(newList, data));
    }

    if (type === "category") {
      let newList = [].concat(data);

      this.props.dispatch(applyDevicesFilter([]));
      this.props.dispatch(applyDevicesCatFilter(newList));
    }
  };

  handleChangeEngineTabs = (value) =>{
    this.setState({engineTabs:value},()=>{

    })
}

  render() {
    const d = this.props.drivers;
    const g = this.props.geofences;
    const { attributes } = this.props.logInUsers;
    const {engineTabs}  = this.state
    const topRow = g && g.length === 0 && d && d.length === 0 ? 4 : 3;
    const View = this.state.show ? (
      <div className="overlayform">
        <h4 className="no-margin mb-5" style={{background:this.props.themecolors.backgroundColor}}>
          <span>
            {this.state.type === "device"
              ? "Devices (" + this.props.totalDevices + ")"
              : this.state.type === "driver"
              ? "Drivers (" + this.state.driverIds.length + ")"
              : ""}
          </span>{" "}
          <span>
            <CloseIcon onClick={this.resetSelectors} />
          </span>
        </h4>
        <div className="form-body" style={{background:this.props.themecolors.backgroundColor}}>
          {this.state.type == "device" ? (
            <DeviceSelector readOnly rows={8} />
          ) : null}
          {this.state.type == "driver" ? (
            <DriverSelector
              readOnly
              applyFilter
              onDriverClick={this.onDriverClick}
              rows={8}
              displayList={this.state.driverIds}
            />
          ) : null}
        </div>
      </div>
    ) : null;

    return (
      //  <Layout
      //   {...this.props}
      //   noSidebar
      //   classFromChildren={"has-padding no-sidebar dashboard-page"}
      //   afterView={View}
      // >
        <Grid container spacing={2} style={{backgroundColor:"#F1F6F9", padding:"10px 44px" }}>
          {/* Row 1 */}
          {/* Row 1  C1*/}
          


          <Grid item xs={12} md={8} >
           <Grid container spacing={2} >
            <Grid item xs={12} md={6} >
         {this.state?.pieChart2 ? 
            <div style={{padding:"12px 18px",  borderRadius:"8px", fontSize: 16,fontWeight:"800" }}>
              {/* <div style={{display:"flex",  alignItems:"center",fontSize: 16,fontWeight:"800" }}></div> */}
              <h3>   {this.props.translate("unitsStatus")}
              <span
                      style={{
                        color: this.props.themecolors.menuActiveBackground,
                        
                        // verticalAlign: "middle",
                        marginLeft: 10,
                        display: "inline-block",
                      }}
                    >
                      {this.state.pieChart2.total || 0}
                  </span>
              </h3>
              <div style={{display:"flex", justifyContent:"space-betwen", marginTop:"10px"}}>
                <div style={{width:"50%", minWidth:"211px", backgroundRepeat:"no-repeat",backgroundSize:"cover",  color:"#fff", height:"84px",
                //  backgroundColor:"green",
                
                   backgroundImage: `url(${onlineSvg})` ,
                  padding:"17px 14px",borderRadius:"8px" }}
                  onClick={(e) =>
                    this.openDeviceSelector("statusOnline", "device")
                  }>
                    <p style={{fontSize:"14px", fontWeight:"600"}}>{this.props.translate("tracker.online")}</p>
                    <p style={{fontSize:"24px", fontWeight:"800"}}>    {this.state?.pieChart2?.online || 0}</p>
                </div>
                <div style={{width:"50%", minWidth:"211px", backgroundRepeat:"no-repeat",backgroundSize:"cover",  color:"#fff", height:"84px", marginLeft:"10px",
                 backgroundImage: `url(${notRegisteredSvg})`, padding:"17px 14px",borderRadius:"8px" }}
                 onClick={(e) =>
                            this.openDeviceSelector("notRegistered", "device")
                          }
                 >
                    <p style={{fontSize:"14px", fontWeight:"600"}}>{this.props.translate("tracker.vehicleEnrollment")}</p>
                    <p style={{fontSize:"24px", fontWeight:"800"}}> {this.state?.pieChart2?.notRegistered || 0}</p>
                </div>
              </div>
              <div style={{display:"flex", justifyContent:"space-betwen", marginTop:"10px"}} 
              >
                <div 
                 onClick={(e) =>
                  this.openDeviceSelector("gpsNotUpdated", "device")
                }
                style={{width:"50%", minWidth:"211px", backgroundRepeat:"no-repeat",backgroundSize:"cover",  color:"#fff", height:"84px", backgroundImage: `url(${gpsNotUpdatedSvg})`, padding:"17px 14px",borderRadius:"8px" }}>
                    <p style={{fontSize:"14px", fontWeight:"600"}}>  {this.props.translate("tracker.gpsNotUpdated")}</p>
                    <p style={{fontSize:"24px", fontWeight:"800"}}>{this.state?.pieChart2?.gpsNotUpdated || 0}</p>
                </div>
                <div 
                
                onClick={(e) =>
                  this.openDeviceSelector("statusOffline", "device")
                }
                style={{width:"50%", minWidth:"211px", backgroundRepeat:"no-repeat",backgroundSize:"cover",  color:"#fff", height:"84px",marginLeft:"10px",backgroundImage: `url(${offlineSvg})`, padding:"17px 14px",borderRadius:"8px" }}>
                    <p style={{fontSize:"14px", fontWeight:"600"}}>{this.props.translate("tracker.offline")}</p>
                    <p style={{fontSize:"24px", fontWeight:"800"}}>{this.state?.pieChart2?.offline || 0}</p>
                </div>
              </div>
            </div>
             : <BarGraphSkeleton3 />}
            </Grid>


            <Grid item xs={12} md={6} >
            <div style={{padding:"12px 18px",  borderRadius:"8px", minHeight:"208px"}}>
            {this.state.drivers && this.state.drivers ? (
                  <>
                  <div style={{display:"flex",  alignItems:"center",fontSize: 16,fontWeight:"800" }}>
              <h3>{this.props.translate("Staff Member")}
                    <span
                      style={{
                        color: this.props.themecolors.menuActiveBackground,
                        
                        // verticalAlign: "middle",
                        marginLeft: 10,
                        display: "inline-block",
                      }}
                    >
                      {this.state.drivers?.total || 0}
                  </span></h3>
                  </div>

                  
                    <div className="driver-state-wrap" style={{marginTop:"3px"}}>
                      <div className="driver-state-container">
                        <div
                          className="driver-state hover"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 20,
                            background:"#fff",
                            color: this.props.themecolors.textColor,
                            padding: "10px 15px",
                          }}
                          onClick={(e) =>
                            this.openDriverSelector(
                              this.state.drivers?.assigned,
                              "driver"
                            )
                          }
                        >
                          <AssignedIcon
                            width={40}
                            height={40}
                            fill="currentColor"
                          />

                          <div style={{ textAlign: "left" }}>
                            <h3 style={{ margin: 0, fontSize: 18 }}>
                              {this.props.translate("assigned")}
                            </h3>
                            <h3 style={{ margin: 0, fontSize: 26 }}>
                              {this.state.drivers?.assigned?.length || 0}
                            </h3>
                          </div>
                        </div>
                        <div
                          className="driver-state hover"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 20,
                            background:"#fff",
                            color: this.props.themecolors.textColor,
                            padding: "10px 15px",
                          }}
                          onClick={(e) =>
                            this.openDriverSelector(
                              this.state.drivers.unassigned,
                              "driver"
                            )
                          }
                        >
                          <UnAssignedIcon
                            width={40}
                            height={40}
                            fill="currentColor"
                          />

                          <div style={{ textAlign: "left" }}>
                            <h3 style={{ margin: 0, fontSize: 18 }}>
                              {this.props.translate("unassigned")}
                            </h3>
                            <h3 style={{ margin: 0, fontSize: 26 }}>
                              {this.state.drivers?.unassigned?.length || 0}
                            </h3>
                          </div>
                        </div>
                        <div
                          className="driver-state hover"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 20,
                            background:"#fff",
                            color: this.props.themecolors.textColor,
                            padding: "10px 15px",
                          }}
                          onClick={(e) =>
                            this.openDriverSelector(
                              this.state.drivers.unknown,
                              "driver"
                            )
                          }
                        >
                          <UnknownIcon
                            width={40}
                            height={40}
                            fill="currentColor"
                          />

                          <div style={{ textAlign: "left" }}>
                            <h3 style={{ margin: 0, fontSize: 18 }}>
                              {this.props.translate("unknown")}
                            </h3>
                            <h3 style={{ margin: 0, fontSize: 26 }}>
                              {this.state.drivers?.unknown?.length || 0 }
                            </h3>
                          </div>
                        </div>
                        <div
                          className="driver-state hover"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 20,
                            background:"#fff",
                            color: this.props.themecolors.textColor,
                            padding: "10px 15px",
                          }}
                          onClick={(e) =>
                            this.openDriverSelector(
                              this.state.drivers.inactive,
                              "driver"
                            )
                          }
                        >
                          <Nosignal
                            width={40}
                            height={40}
                            fill="currentColor"
                          />

                          <div style={{ textAlign: "left" }}>
                            <h3 style={{ margin: 0, fontSize: 18 }}>
                              {this.props.translate("nosignal")}
                            </h3>
                            <h3 style={{ margin: 0, fontSize: 26 }}>
                              {this.state.drivers?.inactive?.length || 0}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) :  <div style={{padding:"12px 18px", backgroundColor:"#fff", borderRadius:"8px"}}>
                <BarGraphSkeleton2 /></div>}

            </div>
            </Grid>

            <Grid container spacing={2} >
            <Grid item xs={12} md={6} >
            {!this.state.enginHourReportLoading && this.state.report?.length ? (
            <div style={{padding:"12px 18px", backgroundColor:"#fff", borderRadius:"8px"}}>
              <h3>  {this.props.translate("Engine")}</h3>
              <div style={{display:"flex", alignItems:"center", margin:"20px 0px "}}>
                  <Button 
                       onClick={()=>{this.handleChangeEngineTabs("engineHours")}}
                       style={{width:"112px", height:"36px", marginLeft:"5px",
                         color:engineTabs === "engineHours" ?  "white" :"black" ,
                         backgroundColor:engineTabs === "engineHours" ? "black" :"white",
                         border:engineTabs === "engineHours" ?  "black" : "2px solid green" ,
                         borderRadius:"8px"
                         }}
                    >
                    
                    {this.props.translate("Hours")}
                    </Button>
                    <Button 
                        onClick={()=>{this.handleChangeEngineTabs("daliyMilage")}}
                        style={{width:"112px", height:"36px", marginLeft:"5px",
                          color:engineTabs === "daliyMilage" ? "white" :"black" ,
                          backgroundColor:engineTabs === "daliyMilage" ? "black" :"white",
                          border:engineTabs === "daliyMilage" ? "black" : "2px solid green",
                          borderRadius:"8px"
                          }}
                  >
                  {this.props.translate("Milage")}
                  </Button>
                  </div>

                  <Scrollbar style={{ height:'365PX',  marginTop:"10px" , }}>
                    <ul style={{ margin: 0, padding:"0px 10px"  }}>
                    {engineTabs === "daliyMilage"? 
                    <>
                      {this.state.report?.[0]?.data?.length
                        ? this.state.report?.[0]?.data
                            ?.filter(row => row.totalDistance > 0) // Filter out rows with totalDistance as 0
                            ?.sort((a, b) => b.totalDistance - a.totalDistance) // Sort in descending order based on totalDistance
                            ?.map((row, index) => (
                            <li
                              // className="motion-status-bar-alt"
                              key={row.id}
                              style={{
                                display: "flex",
                                gap: 10,
                                paddingTop: 5,
                                paddingBottom: 5,
                                flex: 1,
                                alignItems: "center",
                              }}
                            >
                              <div style={{ flex: 1 }}>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    // gap: 1,
                                  }}
                                >
                                  <div  style={{display: "flex",justifyContent:"space-between",  alignItems:"center"}}>
                                  <p >{row.name}</p>
                                  {/* {engineTabs === "daliyMilage"? */}
                                    <span style={{fontWeight:"700"}}>
                                    {(row.totalDistance / 1000)?.toFixed(2)} <span>{" "}km</span>
                                
                                    </span>
                                    {/* :
                                    <span style={{fontWeight:"700"}}>
                                     { row?.hours || 0 }
                                      </span>
                                      } */}
                                    </div>



                                    
                                   <Bar
                                    label={<Translate i18nKey="deviceMileage" />}
                                    value={
                                      this.state.report?.[0]?.data[0].totalDistance
                                        ? parseFloat(
                                            (
                                              ((row.totalDistance || 0) /
                                              this.state.report?.[0]?.data[0].totalDistance) *
                                              100
                                            ).toFixed(2)
                                          )
                                        : 0
                                    }
                                    color={colors1[index % colors1.length]}
                                    
                                  />
                                  {/*
                                  <Bar
                                    label={<Translate i18nKey="reportEngineHours" />}
                                    value={
                                      this.state.report.max.hours
                                        ? parseFloat(
                                            (
                                              ((row.hours || 0) /
                                                this.state.report.max.hours) *
                                              100
                                            ).toFixed(2)
                                          )
                                        : 0
                                    }
                                    color={colors1[index % colors1.length]}
                                  /> */}
                                </div>
                              </div>
                           
                            </li>
                          ))
                        : null}
                        </> : 
                        <>
                         {this.state.report?.[1]?.data?.length
                        ? this.state.report?.[1]?.data
                        ?.filter(row => row.hoursRaw > 0) // Filter out rows with totalDistance as 0
                        ?.sort((a, b) => b.hoursRaw - a.hoursRaw) // Sort in descending order based on totalDistance
                        ?.map((row, index) => (
                            <li
                              // className="motion-status-bar-alt"
                              key={row.id}
                              style={{
                                display: "flex",
                                gap: 10,
                                paddingTop: 5,
                                paddingBottom: 5,
                                flex: 1,
                                alignItems: "center",
                              }}
                            >
                              <div style={{ flex: 1 }}>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    // gap: 1,
                                  }}
                                >
                                  <div  style={{display: "flex",justifyContent:"space-between",  alignItems:"center"}}>
                                  <p >{row.name}</p>
                                  {/* {engineTabs === "daliyMilage"? */}
                                    <span style={{fontWeight:"700"}}>
                                      {row.hours} <span>{" "}</span>
                                
                                    </span>
                                    {/* :
                                    <span style={{fontWeight:"700"}}>
                                     { row?.hours || 0 }
                                      </span>
                                      } */}
                                    </div>



                                    
                                  {/* <Bar
                                    label={<Translate i18nKey="deviceMileage" />}
                                    value={
                                      this.state.report.max.totalDistance
                                        ? parseFloat(
                                            (
                                              ((row.totalDistance || 0) /
                                                this.state.report.max.totalDistance) *
                                              100
                                            ).toFixed(2)
                                          )
                                        : 0
                                    }
                                    color={colors1[index % colors1.length]}
                                    
                                  />*/}
                                  <Bar 
                                    label={<Translate i18nKey="reportEngineHours" />}
                                    value={
                                      this.state.report?.[1]?.data[0].hoursRaw
                                        ? parseFloat(
                                            (
                                              ((row.hoursRaw || 0) /
                                              this.state.report?.[1]?.data[0].hoursRaw) *
                                              100
                                            ).toFixed(2)
                                          )
                                        : 0
                                    }
                                    color={colors1[index % colors1.length]}
                                  /> 
                                </div>
                              </div>
                           
                            </li>
                          ))
                        : null}
                        </>}
                    </ul>
                 
                  </Scrollbar>
            </div>
             ) : <div style={{padding:"12px 18px", backgroundColor:"#fff", borderRadius:"8px"}}>
                       <BarGraphSkeleton2 /></div>} 
            </Grid>
            <Grid item xs={12} md={6} >
              {this.props.geofences && this.props.geofences.length ? (
            <div style={{padding:"12px 18px", backgroundColor:"#fff", borderRadius:"8px", minHeight:"485px"}}>
              <h3> {this.props.translate("sharedGeofence")}</h3>
                      <GeoList
                        {...this.props}
                        className={"motion-status-bar-alt"}
                        data={this.props.geofences}
                        themecolors={this.props.themecolors}
                        showSelectedUnits={this.openGeoSelector}
                        geoIds={this.props.insideGeofence}
                      />
            </div>
                ) : 
                <div style={{padding:"12px 18px", backgroundColor:"#fff", borderRadius:"8px"}}>
                <BarGraphSkeleton2 /></div>}

            </Grid>
         </Grid>
         </Grid>
        </Grid>

          {/* Row 1  C2*/}

           <Grid item xs={12} md={4} >
              
              <Grid container spacing={2} >
                <Grid item xs={12} >
                    <div style={{padding:"12px 18px", backgroundColor:"#fff", borderRadius:"8px", maxHeight:"360px"}}>
                    {this.state.pie1 && this.state.pie1.total ? (
                      <>
                      <h3> {this.props.translate("unitsTypes")}</h3>
                      <Scrollbar style={{ height:'300px',  marginTop:"10px"  }}>
                        <ul className="md-bar-graphlist" >
                            <MotionList
                              category
                              className={"motion-status-bar-inline"}
                              data={this.state.pie1}
                              showSelectedUnits={this.openCategorySelector}
                            />
                        </ul>
                      </Scrollbar>
                      </>
                    ) : (
                      <BarGraphSkeleton2 />
                    )}
                    </div>
                </Grid>
                <Grid item xs={12} >
                <div style={{padding:"12px 18px", backgroundColor:"#fff", borderRadius:"8px", maxHeight:"360px"}}>
                {this.state.resArr && this.state.resArr.total ? (
                    <>
                      <h3> {this.props.translate("motionStatus")}</h3>
                        <Scrollbar style={{ height:'300px', marginTop:"10px" }}>
                         <ul className="md-bar-graphlist" >
                            <MotionList
                              data={this.state.resArr}
                              className="motion-status-bar-alt"
                              showSelectedUnits={this.openMotionSelector}
                            />
                         </ul>
                      </Scrollbar>
                    </>
                    ) : (
                      <BarGraphSkeleton2 />
                    )}
                    </div>

                </Grid>
            </Grid>
           </Grid>
        </Grid>
        // </Layout>
     );
  }
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

function BarGraphSkeleton(props) {
  return (
    <div
      style={{
        marginTop: 20,
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <div style={{ marginBottom: 36 }}>
        <Skeleton className="skeleton-pulse" width={100} height={20} />
      </div>
      <table
        style={{ width: "100%", boderCollapse: "collapse" }}
        border="0"
        cellPadding="0"
        cellSpacing="0"
      >
        <tbody>
          <tr>
            <td
              align="right"
              width={200}
              style={{ borderRight: "1px solid #ccd6eb", padding: "7px 20px" }}
            >
              <Skeleton className="skeleton-pulse" width={150} height={14} />
            </td>
            <td style={{ padding: "7px 20px" }}>
              <Skeleton className="skeleton-pulse" width={400} height={14} />
            </td>
          </tr>
          <tr>
            <td
              align="right"
              style={{ borderRight: "1px solid #ccd6eb", padding: "7px 20px" }}
            >
              <Skeleton className="skeleton-pulse" width={200} height={14} />
            </td>
            <td style={{ padding: "7px 20px" }}>
              <Skeleton className="skeleton-pulse" width={260} height={14} />
            </td>
          </tr>
          <tr>
            <td
              align="right"
              style={{ borderRight: "1px solid #ccd6eb", padding: "7px 20px" }}
            >
              <Skeleton className="skeleton-pulse" width={170} height={14} />
            </td>
            <td style={{ padding: "7px 20px" }}>
              <Skeleton className="skeleton-pulse" width={370} height={14} />
            </td>
          </tr>
          <tr>
            <td
              align="right"
              style={{ borderRight: "1px solid #ccd6eb", padding: "7px 20px" }}
            >
              <Skeleton className="skeleton-pulse" width={250} height={14} />
            </td>
            <td style={{ padding: "7px 20px" }}>
              <Skeleton className="skeleton-pulse" width={330} height={14} />
            </td>
          </tr>
          <tr>
            <td
              align="right"
              style={{ borderRight: "1px solid #ccd6eb", padding: "7px 20px" }}
            >
              <Skeleton className="skeleton-pulse" width={230} height={14} />
            </td>
            <td style={{ padding: "7px 20px" }}>
              <Skeleton className="skeleton-pulse" width={450} height={14} />
            </td>
          </tr>
          <tr>
            <td
              align="right"
              style={{ borderRight: "1px solid #ccd6eb", padding: "7px 20px" }}
            >
              <Skeleton className="skeleton-pulse" width={120} height={14} />
            </td>
            <td style={{ padding: "7px 20px" }}>
              <Skeleton className="skeleton-pulse" width={320} height={14} />
            </td>
          </tr>
        </tbody>
      </table>
      <div style={{ marginBottom: 20, marginTop: 30, display: "flex" }}>
        <div
          style={{
            display: "inline-flex",
            alignItems: "center",
            paddingRight: 40,
          }}
        >
          <div style={{ marginRight: 9 }}>
            <Skeleton
              className="skeleton-pulse"
              variant="circle"
              width={12}
              height={12}
            />
          </div>
          <Skeleton className="skeleton-pulse" width={60} height={14} />
        </div>
        {props.type === 1 && (
          <div
            style={{
              display: "inline-flex",
              alignItems: "center",
              paddingRight: 40,
            }}
          >
            <div style={{ marginRight: 9 }}>
              <Skeleton
                className="skeleton-pulse"
                variant="circle"
                width={12}
                height={12}
              />
            </div>
            <Skeleton className="skeleton-pulse" width={60} height={14} />
          </div>
        )}
      </div>
    </div>
  );
}

function BarGraphSkeleton2() {
  return (
    <div
      style={{
        marginTop: 20,
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ display: "flex", width: "100%" }}>
        <Skeleton className="skeleton-pulse" width={"100%"} height={35} />
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        <Skeleton className="skeleton-pulse" width={"100%"} height={35} />
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        <Skeleton className="skeleton-pulse" width={"100%"} height={35} />
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        <Skeleton className="skeleton-pulse" width={"100%"} height={35} />
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        <Skeleton className="skeleton-pulse" width={"100%"} height={35} />
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        <Skeleton className="skeleton-pulse" width={"100%"} height={35} />
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        <Skeleton className="skeleton-pulse" width={"100%"} height={35} />
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        <Skeleton className="skeleton-pulse" width={"100%"} height={35} />
      </div>
    </div>
  );
}
function BarGraphSkeleton3() {
  return (
    <div
      style={{
        marginTop: 20,
        padding: 10,
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Skeleton className="skeleton-pulse" width={"100%"} height={35} />
        <Skeleton className="skeleton-pulse" width={"48%"} height={116} />
        <Skeleton className="skeleton-pulse" width={"48%"} height={116} />
        <Skeleton className="skeleton-pulse" width={"48%"} height={116} />
        <Skeleton className="skeleton-pulse" width={"48%"} height={116} />
      </div>
    </div>
  );
}

// export const MotionList = ({
//   data,
//   showSelectedUnits,
//   className,
//   category,
// }) => {
//   const html = data.data.map((d) => (
//     <div
//       key={d.name}
//       className={"motion-status-bar hover " + className}
//       onClick={() =>
//         showSelectedUnits &&
//         showSelectedUnits(d.key, category ? "category" : "device", d.name)
//       }
//     >
//       <span style={{ background: d.color }} className="motion-status-border" />{" "}
//       <span className="motion-status-img">{d.image}</span>{" "}
//       <span className="motion-status-name">{<Translate i18nKey={d.name} />}</span>
//       <span className="motion-status-count">{d.value}</span>
//     </div>
//   ));
//   return <>{html}</>;
// };


export const MotionList = ({
  data,
  showSelectedUnits,
  className,
  category,
}) => {
    let colors = ["#F6F0F9","#F0F1F9","#E8F1F8","#EAF7EF","#FFF0F0","#FFF6E5","#FFF0F0","#FFF6E5","#E5ECEF","#F0F1F9"];
    const sortedData = data.data.sort((a, b) => b.value - a.value);
  const html = sortedData?.map((d, index) => (
    <li key={d.name} 
      onClick={() =>
        showSelectedUnits &&
        showSelectedUnits(d.key, category ? "category" : "device", d.name)
      }
    style={{minWidth:"400px", minHeight:"32px" ,fontSize:"14px",  display:"flex", alignItems:"center",justifyContent:"space-between",padding:category ? "0px 17px 0px 0px" : "0px 17px 0px 10px" ,backgroundColor: colors[index % colors.length],  borderRadius:"8px", margin:'0 0 10px 0',}}>
    {/* <span style={{ background: d.color }} className="motion-status-border" />{" "} */}
   <div  style={{display:"flex",alignItems:"center"}}>
   <span  className="motion-status-border" />{" "}
   <span className={"motion-status-bar hover " + className} >
      <span className="motion-status-img" style={{fontWeight:600}} >{d.image}</span>{" "} </span>
      <span className="motion-status-name" style={{marginLeft:"10px", fontWeight:400}} >{<Translate i18nKey={d.name} />}</span>
      </div>
      <span className="motion-status-count" style={{fontWeight:600}}>{d.value}</span>
    </li>
  ));
  return <>{html}</>;
};

export const GeoList = ({
  data,
  themecolors,
  showSelectedUnits,
  className,
  geoIds,
  translate,
}) => {
  data.sort(
    (b, a) =>
      ((geoIds[a.id] && geoIds[a.id].length) || 0) -
      ((geoIds[b.id] && geoIds[b.id].length) || 0)
  );


  let piseries = [];
  data.map((v) => {
    piseries.push({ name: v.name, y: geoIds[v.id]?.length || 0 });
    return null;
  });
  let colors = ["#003F5C", "#29AF5C", "#009E6F", "#008C7D", "#1E72B8", "#6D71C5", "#007981", "#00667D", "#005270", "#6FBD43"];

  let pieData = [
    {
      colorByPoint: true,
      data: piseries,
      innerSize: "60%",
      colors: colors,
    },
  ];

  let pieChart = {
    config: pieChartDefault().initializeWithOptions({
      chart: {
        height: "380px",
      },
      pie: {
        shadow: false,
        center: ["50%", "50%"],
        showInLegend: true,
      },
      title: {
        // text: translate("sharedGeofence"),
        // text: "sharedGeofence",
        style: {
          font: 'normal 16px "Roboto"',
          color: themecolors.textColor,
          textTransform: "none",
        },
      },
      series: pieData,
      legend: {
        enabled: true,
        useHTML: true,
        maxHeight: 150,
        
        itemStyle: {
          fontWeight: 'normal', // You can adjust the style of the legend items
        },
        labelFormatter: function () {
          const formattedName = this.name.charAt(0).toUpperCase() + this.name.slice(1); // Capitalize first letter
          return '<b>' + formattedName + '</b> : ' + this.y;
        },
        layout: 'horizontal', // Display legend items horizontally
        align: 'center', // Align legend to the center
        navigation: {
          enabled: true, // Enable navigation through legend items
          style: {
            fontSize: '12px', // Adjust the font size of navigation buttons
          },
        },
      },
     
      yAxis: {
        gridLineWidth: 0,
      },
      tooltip: {
        formatter: function () {
          return (
            "<strong>" +
            this.key +
            "</strong>" +
            " <br /> <strong>Units " +
            this.y +
            " <br />" +
            parseFloat(this.percentage.toFixed(2)) +
            "%</strong>"
          );
        },
      },
    }),
    show: true,
  };

  const html = (
    <div>
      {/* <ReactHighcharts
        domProps={{ id: "enginHourReport" }}
        config={pieChart.config}
      /> */}
    </div>
  );

  // const html = data.map((d, i) => {
  //   return (
  //     <div
  //       key={d.id}
  //       className={'motion-status-bar hover ' + className}
  //       onClick={() => showSelectedUnits && showSelectedUnits(d.id, 'geofence', d.name)}
  //     >
  //       <span
  //         style={{ background: colors[i % colors.length] }}
  //         className='motion-status-border'
  //       />
  //       <span className='motion-status-img' />
  //       <span className='motion-status-name'>{d.name}</span>
  //       <span className='motion-status-count'>
  //         {geoIds && geoIds[d.id] && geoIds[d.id].length
  //           ? geoIds[d.id].length
  //           : 0}
  //       </span>
  //     </div>
  //   )

  // })
  return <div>{html}</div>;
};

const mapStateToProps = (state) => {
  let driverIdsList = [];
  Object.values(state.allComputedAttributes).map(({ item, deviceIds }) => {
    // if(deviceIds && item.attribute === 'driverUniqueId') { { staff are not showing if item.attribute doesnot have driverUniqueId. We added manualy driverUniqueId at units->sensors->add Binders and added driverUniqueId
    if (deviceIds && item.attribute) {
      driverIdsList = [...driverIdsList, ...deviceIds];
    }
    return null;
  });
  return {
    ServerSetting: state.ServerSetting,
    logInUsers: state.logInUsers,
    engineHourSummery: state.engineHourSummery,
    devices3: state.devices3,
    geofences: state.geoFence,
    totalDevices: state.devices.total,
    allNotifications: state.allNotifications,
    drivers: state.drivers,
    positions: state.positions,
    insideGeofence: state.insideGeofence,
    allComputedAttributes: driverIdsList,
    themecolors: state.themeColors,
  };
};

export default connect(mapStateToProps)((Dashboard));

const Bar = ({ label, value, color }) => {
  return (
    <div>
      <Tooltip
        placement={"top"}
        classes={{
          popper: "menu-popper",
          tooltip: "menu-popper-tooltip",
        }}
        title={
          <>
            {label} : {value} %{" "}
          </>
        }
      >
        <a
          style={{
            display: "flex",
            flex: 1,
            fontSize: 0,
            width: value + "%",
            background: color,
            height: 15,
            borderRadius: 20,
          }}
        >
          kjhdjslahsj dk
        </a>
      </Tooltip>
    </div>
  );
};
